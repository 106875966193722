
import Component, { mixins } from 'vue-class-component'
import { Vue } from 'vue-property-decorator'

const AudioContext = window.AudioContext || window.webkitAudioContext

const DEFAULT_CLIP_LEVEL = 0.98
const DEFAULT_AVERAGING = 0.95

const MAX_AVERAGE_VOLUME_NOISE = 0.1

/**
 * This component is called when user state:
 * - permmissionToCheckAudio === true
 * - audioCheckIsSuccessful === false
 * The purpose of this component is to complete the background audio check,
 * Then to call an action in User.ts which calls the BE with the audio data.
 * That api call returns whether or not the audio is acceptable and updates audioCheckIsSuccessful.
 */
// Define the props by using Vue's canonical way.
const BackgroundAudioCheckProps = Vue.extend({
  props: {
    mediaStream: MediaStream
  }
})

@Component
export default class BackgroundAudioCheck extends mixins(BackgroundAudioCheckProps, Vue) {
  audioData: number [] | null = null
  audioContext: AudioContext = new AudioContext()
  audioBackgroundNoiseLow = true
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  analyserNode: any = null

  processingNode: ScriptProcessorNode|undefined
  audioSourceNode: MediaStreamAudioSourceNode|undefined

  averaging = DEFAULT_AVERAGING
  volume = 0.0
  clipLevel = DEFAULT_CLIP_LEVEL
  clipping = false
  lastClip = 0

  totalVolume = 0.0
  occurenceVolumeCalculated = 0

  mounted () {
    // On mount, make the api call to the backend,
    // To retrieve the status on whether or not the users background audio is acceptable.
    this.checkAudioBackgroundNoise()
  }

  checkAudioBackgroundNoise (): void {
    // -- STUB --
    // This method will be responsible for recording background audio data and returning that data.
    // The returned data will be sent to the backend to be validated.
    this.audioSourceNode = this.audioContext.createMediaStreamSource(this.mediaStream)
    this.processingNode = this.audioContext.createScriptProcessor(2048, 1, this.audioSourceNode.channelCount)
    this.processingNode.onaudioprocess = this.handleScriptProcessAudioProcess

    // audioSourceNode.connect(this.analyserNode)
    this.audioSourceNode.connect(this.processingNode)
    this.processingNode.connect(this.audioContext.destination)

    // Set time out to check the final results of the onaudioprocess event
    setTimeout(this.handleCheckFinalBackgroundNoiseResult, 3000) // 3 seconds. (3000ms)
  }

  handleScriptProcessAudioProcess (event: AudioProcessingEvent) {
    const buffer: Float32Array = event.inputBuffer.getChannelData(0)
    let sum = 0

    // Do a root-mean-square on the samples: sum up the squares...
    buffer.forEach(bufferSingleValue => {
      if (Math.abs(bufferSingleValue) >= this.clipLevel) {
        this.clipping = true
        this.lastClip = window.performance.now()
      }
      sum += bufferSingleValue * bufferSingleValue
    })

    // ... then take the square root of the sum.
    const rms = Math.sqrt(sum / buffer.length)

    // Now smooth this out with the averaging factor applied
    // to the previous sample - take the max here because we
    // want "fast attack, slow release."
    this.volume = Math.max(rms, this.volume * this.averaging)

    this.totalVolume += this.volume

    this.occurenceVolumeCalculated += 1
  }

  handleCheckFinalBackgroundNoiseResult () {
    this.mediaStream.getTracks().forEach((track: MediaStreamTrack) => track.stop())
    this.audioContext.close()

    const averageVolume = this.totalVolume / this.occurenceVolumeCalculated

    this.audioBackgroundNoiseLow = averageVolume < MAX_AVERAGE_VOLUME_NOISE

    const audioNode: MediaStreamAudioSourceNode = this.audioSourceNode as MediaStreamAudioSourceNode
    audioNode.disconnect()

    const processNode: ScriptProcessorNode = this.processingNode as ScriptProcessorNode
    processNode.onaudioprocess = null
    processNode.disconnect()

    this.$emit('on-background-noise-checked', this.audioBackgroundNoiseLow)
  }
}
